// @vendors
import * as React from 'react'
import cx from 'classnames'
import { Helmet } from 'react-helmet'

// @components
import { Header } from '../../components/header'
import { Footer } from '../../components/footer'

// @fontawesome CSS
import '../../assets/fonts/font-awesome/css/fontawesome.css'
import '../../assets/fonts/font-awesome/css/brands.css'
import '../../assets/fonts/font-awesome/css/light.css'
import '../../assets/fonts/font-awesome/css/regular.css'
import '../../assets/fonts/font-awesome/css/solid.css'
import '../../assets/fonts/font-awesome/css/duotone.css'
import '../../assets/fonts/font-awesome/css/thin.css'

// @styles
import 'antd/dist/antd.css'
import './styles.scss'

export const Layout = ({ children, header, footer, activeDoc, className }) => {
    const classes = cx(
        'page-container',
        className
    )

    return (
        <div
            className={cx(classes)}
        >
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
            </Helmet>
            <Header data={header} activeDoc={activeDoc} />
            {children}
            <Footer data={footer} activeDoc={activeDoc} />
        </div>
    )
}