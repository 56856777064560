// @vendors
import React from 'react'
import cx from 'classnames'

// @styles
import './styles.scss'

export default function Companies({ slice }) {
    const data = slice.primary
    const items = slice.items

    return (
        <section
            className={cx(
                'section-default',
                'companies',
                `c-${data.background}`,
                {
                    'wave': data.type === 'Wave',
                    'text-dark': data.color === 'Oscuro',
                    'text-light': data.color === 'Claro'
                }
            )}
        >
            <div className='container'>
                {data.title && (
                    <h3 className='companies-title title'>
                        <strong>{data.title}</strong>
                    </h3>
                )}
                <ul className='companies-list'>
                    {items.map((item, index) => (
                        <li
                            className='company-item'
                            key={`company-item-${index}`}
                        >
                            <img
                                src={item.logo.url}
                                alt={item.logo.alt}
                                className='company-image'
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}