import React from "react"
import { Input } from 'antd';
import { DEFAULT } from "../../../../constants/strings";

export default function FieldMessages({ lang, model }) {
    return (
        <>
            <Input
                hidden
                name="autoreply_subject"
                defaultValue={DEFAULT.generics.forms[model][lang].template.autoreply.subject}
            />
            <Input
                hidden
                name="autoreply_fromname"
                defaultValue={DEFAULT.generics.forms[model][lang].template.autoreply.fromName}
            />
            <Input
                hidden
                name="autoreply_message"
                defaultValue={DEFAULT.generics.forms[model][lang].template.autoreply.message}
            />
            <Input
                hidden
                name="autoreply_footer"
                defaultValue={DEFAULT.generics.forms[model][lang].template.autoreply.footer}
            />
        </>
    )
}