// @vendors
import React from "react";
import cx from 'classnames'

// @components
import ContactMessage from "../generics/contact-message";

// @styles
import './styles.scss'

export default function ContactUs({ slice, context }) {
    const info = slice.primary
    const actions = slice.items
    
    return (
        <section
            className={cx(
                'section-default',
                'contact-us',
                'shape',
                `c-${info.background}`,
                `text-${info.align}`,
                {
                    'wave': info.type === 'Wave',
                    'text-dark': info.color === 'Oscuro',
                    'text-light': info.color === 'Claro'
                }
            )}
        >
            <div className="container">
                <ContactMessage
                    data={context.generics.data.contactus[0]}
                    actions={actions}
                />
            </div>
        </section>
    )
}