import React from 'react'
import { Helmet } from 'react-helmet'
import { DEFAULT } from '../../constants/strings'

export default function SeoAttributes({ data, script }) {
    if (!data) return null

    return (
        <Helmet
            title={`${data.title} ${DEFAULT.site.name}`}
            script={[
                {
                    "src": script,
                    "type": "text/javascript",
                    "crossorigin": "anonymous"
                }
            ]}
        />
    )
}