import React, { useState } from "react"
import { Form, Input, Select } from 'antd';
import { convertToSnakeCase } from "../../../../utils/converts";
import { DEFAULT } from "../../../../constants/strings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

export default function FieldSector({ slice, context }) {
    const [other, setOther] = useState(false)
    const [sectorSelected, setSectorSelected] = useState('')
    const data = slice.primary
    const sectors = context.sectors
    const lang = context.lang

    const handleChange = (value) => {
        setSectorSelected(value)
        if (value === DEFAULT.generics.fields.sector[lang].other) {
            setOther(true)
        } else {
            setOther(false)
        }
    }

    return (
        <>
            <Form.Item
                label={data.label}
                name={convertToSnakeCase(data.label)}
                rules={[
                    { required: data.required, message: data.validation }
                ]}
            >
                <Select
                    onChange={handleChange}
                    placeholder={DEFAULT.generics.fields.sector[lang].placeholder}
                    showSearch
                    suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
                >
                    {sectors.map(item => (
                        <Select.Option
                            value={item}
                            key={convertToSnakeCase(item)}
                        >
                            {item}
                        </Select.Option>
                    ))}
                    <Select.Option
                        value={DEFAULT.generics.fields.sector[lang].other}
                    >
                        {DEFAULT.generics.fields.sector[lang].other}
                    </Select.Option>
                </Select>
            </Form.Item>
            <Input
                name={convertToSnakeCase(data.label)}
                value={sectorSelected.toUpperCase()}
                hidden
            />
            {other && (
                <Form.Item
                    label={DEFAULT.generics.fields.sector[lang].otherLabel}
                    name='message'
                    rules={[
                        { required: true, message: 'Debes escificarnos más del otro sector' }
                    ]}
                >
                    <Input.TextArea
                        rows={4}
                        maxLength={200}
                        name='message'
                        showCount
                    />
                </Form.Item>
            )}
        </>
    )
}