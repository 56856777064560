// @vendors
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import cx from 'classnames'

import { DEFAULT } from "../../../constants/strings";

// @styles
import './styles.scss'

export default function MessageForm({ lang, type, model }) {
    return (
        <div className={cx(
            'message-form',
            `message-form-${type}`
        )}>
            <div className="message-form-icon">
                <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="subtitle">
                {DEFAULT.generics.forms[model][lang][type].title}
            </div>
            <p className="parag">
                {DEFAULT.generics.forms[model][lang][type].description}
            </p>
        </div>
    )
}