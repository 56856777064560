import Banner from '../components/banner'
import Stats from '../components/stats'
import HowItWorks from '../components/how-it-works'
import Integrations from '../components/integrations'
import Companies from '../components/companies'
import MakeDifference from '../components/make-difference'
import Stacked from '../components/stacked'
import Testimonial from '../components/testimonial'
import ContactUs from '../components/contact-us'
import Text from '../components/text'

export const components = {
    banner: Banner,
    asi_funciona: HowItWorks,
    estadisticas: Stats,
    integraciones: Integrations,
    empresas: Companies,
    hacemos_la_diferencia: MakeDifference,
    stacked: Stacked,
    testimonio: Testimonial,
    contactanos: ContactUs,
    texto: Text
}