// @vendors
import * as React from 'react'
import { graphql } from 'gatsby'
import { Script } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

// @compoenets
import { components } from '../../components'
import SeoAttributes from '../../components/seo'

// @layout
import { Layout } from '../../layouts/generic'

const SectorTemplate = ({ data }) => {
    if (!data) return null

    const page = data.prismicSector || {}
    const header = data.prismicHeader || {}
    const footer = data.prismicFooter || {}
    const generics = data.prismicGenerics || {}

    const { lang, type, url } = page || {}
    const alternateLanguages = page.alternate_languages || []

    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const contextPage = {
        generics
    }

    return (
        <>
            <Script
                key="pratech-parly-webchat" 
                id="pratech-parly-webchat" 
                data-id-bot="61d300cc31deaecde4108cb3" 
                src="https://pratech-parly-webchat-qa.us-east.mybluemix.net/webchat/widget.js"
                onLoad={() => { 
                        console.log('iniciando pratech-parly-webchat')
                        pratech.parly.initChat();
                    }
                } />
            <Layout 
                header={header}
                footer={footer}
                activeDoc={activeDoc}
            >
                <SeoAttributes data={page.data.properties[0]} script={page.data.script} />
                <SliceZone
                    slices={page.data.body}
                    components={components}
                    context={contextPage}
                />
            </Layout>
        </>
        
    )
}

export const query = graphql`
  query SectorQuery($id: String, $lang: String) {
    prismicSector(id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        alternate_languages {
            uid
            type
            id
            lang
        }
        lang
        url
        uid
        type
        data {
            script
            properties {
                description
                keywords
                title
            }
            body {
                ... on PrismicSliceType {
                    id
                    slice_type
                    slice_label
                }
                ... on PrismicSectorDataBodyBanner {
                    items {
                        color
                        external_content
                        icon
                        name
                        type
                        link {
                            id
                            link_type
                            lang
                            type
                            uid
                            url
                        }
                    }
                    primary {
                        background
                        orientation
                        color
                        description {
                            richText
                        }
                        image {
                            alt
                            url
                        }
                        title
                        type
                    }
                }
                ... on PrismicSectorDataBodyStacked {
                    items {
                        description
                        title
                        shape
                        image {
                            alt
                            url
                        }
                    }
                    primary {
                        align
                        background
                        color
                        message: description
                        extra
                        orientation
                        support
                        title
                        type
                    }
                }
                ... on PrismicSectorDataBodyEstadisticas {
                    items {
                        message
                       value
                    }
                    primary {
                        title
                        wave
                        background
                        color
                    }
                }
                ... on PrismicSectorDataBodyHacemosLaDiferencia {
                    items {
                        title
                        description
                        icon
                    }
                    primary {
                        background
                        color
                        description {
                            richText
                        }
                        title_md: title {
                            richText
                        }
                    }
                }
                ... on PrismicSectorDataBodyContactanos {
                    primary {
                        align
                        background
                        color
                        type
                    }
                    items {
                        button_link {
                            id
                            lang
                            type
                            uid
                            url
                        }
                        button_name
                        color
                        type
                    }
                }
            }
        }
    }

    prismicGenerics(lang: { eq: $lang }) {
        _previewable
        lang
        type
        data {
            contactus {
                description
                title
                image {
                    url
                    alt
                }
                alternative
            }
            support {
                button_name
                message
                image {
                    alt
                    url
                }
                button_link {
                    url
                    uid
                    type
                    id
                }
            }
        }
    }
    prismicHeader(lang: { eq: $lang }) {
        ...HeaderFragment
    }
    prismicFooter(lang: { eq: $lang }) {
        ...FooterFragment
    }
}`

export default withPrismicPreview(SectorTemplate)