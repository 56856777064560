// @vendors
import { Button } from 'antd'
import React from 'react'

// @styles
import './styles.scss'

export default function HowItWorks({ slice }) {
    const items = slice.items
    const data = slice.primary

    return (
        <section className='section-default how-it-works wave'>
            <div className='container'>
                {data.title && (
                    <h3 className='title'>
                        <strong>{data.title}</strong>
                    </h3>
                )}
                <div className='how-it-works-content'>
                    {items.map((item, index) => (
                        <div
                            className='how-it-works-item'
                            key={`how-it-works-item-${index}`}
                        >
                            <div className='how-it-works-title'>
                                <div className='how-it-works-icon'>
                                    <em className={`far fa-${item.icon}`} />
                                </div>
                                {item.title}
                            </div>

                            <p className='parag parag-less'>
                                {item.description}
                            </p>
                        </div>
                    ))}
                </div>
                <Button
                    type='outline ant-btn-secondary'
                    data-id='says-mas'
                >
                    Cuéntame más
                </Button>
            </div>
        </section>
    )
}