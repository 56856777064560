import { PrismicRichText } from "@prismicio/react";
import React from "react";

import './styles.scss'

export default function Text({ slice }) {
    console.log(slice);
    const data = slice.primary.contenido_politica
    return (
        <div className="container">
            <div className="richtext">
                <PrismicRichText
                    field={data.richText}
                    components={{
                        heading2: ({ children }) => <h2 className="subtitle">{children}</h2>,
                        list: ({ children }) => <ol className="list-normal">{children}</ol>,
                        listItem: ({ children }) => <li className="parag">{children}</li>,
                        paragraph: ({ children }) => <p className="parag">{children}</p>,
                    }}
                />
            </div>
        </div>
    )
}