export const DEFAULT = {
    site: {
        name: '- Parly'
    },
    generics: {
        buttons: {
            'es-co': {
                goHome: 'Ir al home'
            },
            'en-us': {
                goHome: 'Go home'
            }
        },
        forms: {
            request: {
                'es-co': {
                    success: {
                        title: '¡Solicitud enviada!',
                        description: 'Gracias por contactarte con nosotros, será un placer ayudarte en el cambio que le conviene a tu negocio.'
                    },
                    template: {
                        autoreply: {
                            subject: ', estamos trabajando en tu demo!',
                            fromName: 'Equipo de Parly',
                            message: 'Nuestro equipo está trabajando en un demo para ti. En un lapso de 24 a 48 horas uno de nuestros representantes te estará contactando.',
                            footer: 'Saludos,'
                        }
                    }
                },
                'en-us': {
                    success: {
                        title: 'Request sent!',
                        description: 'Thank you for contacting us, it will be a pleasure to help you in the change that suits your business.'
                    },
                    template: {
                        autoreply: {
                            subject: ', we are working on your demo!',
                            fromName: 'Parly Team',
                            message: 'Our team is working on a demo for you. In a period of 24 to 48 hours one of our representatives will be contacting you.',
                            footer: 'Best regards,'
                        }
                    }
                }
            },
            contact: {
                'es-co': {
                    success: {
                        title: '¡Yup!',
                        description: 'Gracias por contactarte con nosotros, será un placer ayudarte en el cambio que le conviene a tu negocio.'
                    },
                    template: {
                        autoreply: {
                            subject: ', pronto nos pondremos en contacto contigo!',
                            fromName: 'Equipo de Parly',
                            message: 'Estarmos encantados de ayudarte en comenzar el cambio que le conviene a tu negocio. Un representante de nuestro equipo pronto se pondrá en contacto contigo.',
                            footer: 'Saludos,'
                        }
                    }
                },
                'en-us': {
                    success: {
                        title: 'Yup!',
                        description: 'Thank you for contacting us, it will be a pleasure to help you in the change that suits your business.'
                    },
                    autoreply: {
                        subject: ', pronto nos pondremos en contacto contigo!',
                        fromName: 'Equipo de Parly',
                        message: 'Estarmos encantados de ayudarte en comenzar el cambio que le conviene a tu negocio. Un representante de nuestro equipo pronto se pondrá en contacto contigo.',
                        footer: 'Saludos,'
                    }
                }
            }
        },
        fields: {
            sector: {
                'es-co': {
                    placeholder: 'Seleccione una opción',
                    other: 'Otro',
                    otherLabel: 'Por favor, especifíquenos',
                    otherValidation: 'Debes escificarnos más del otro sector'
                },
                'en-us': {
                    placeholder: 'Select an option',
                    other: 'Other',
                    otherLabel: 'Please specify us',
                    otherValidation: 'You should tell us more about the other sector'
                }
            }
        },
        legal: {
            'es-co': {
                privacy: 'Política de privacidad'
            },
            'en-us': {
                privacy: 'Privacy Policy'
            }
        }
    }
}