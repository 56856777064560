import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { AppContextProvider } from './src/contexts/global'
import { linkResolver } from './src/utils/linkResolver'

export const wrapRootElement = ({ element }) => (
    <AppContextProvider>
        <PrismicProvider
            linkResolver={linkResolver}
            internalLinkComponent={({ href, ...props }) => (
                <Link to={href} {...props} />
            )}
        >
            <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
                {element}
            </PrismicPreviewProvider>
        </PrismicProvider>
    </AppContextProvider>
)