// @vendors
import { Button, Space } from 'antd'
import { Link } from 'gatsby'
import React from 'react'
import { linkResolver } from '../../utils/linkResolver'
import cx from 'classnames'

// @styles
import './styles.scss'

export default function Integrations({ slice }) {
    const data = slice.primary
    const actions = slice.items

    return (
        <section
            className={cx(
                'section-default integrations wave',
                `c-${data?.background}`,
                {
                    'wave': data.type === 'Wave',
                    'text-dark': data?.color === 'Oscuro',
                    'text-light': data?.color === 'Claro'
                }
            )}
        >
            <div className='container'>
                {data.title && (
                    <h3 className='title'>
                        <strong> {data.title}</strong>
                    </h3>
                )}
                <div className='integrations-content'>
                    <ul className='integrations-apps'>
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                        <li className='integrations-apps-item' />
                    </ul>
                    <div className='integrations-message'>
                        {data.subtitle && (
                            <h1 className='integrations-subtitle subtitle'>
                                <strong>{data.subtitle}</strong>
                            </h1>
                        )}
                        {data.message && (
                            <p className='parag'>
                                {data.message}
                            </p>
                        )}
                        <Space className='integrations-actions'>
                            {actions && actions.map((item, index) => (
                                <Link
                                    to={linkResolver(item.link)}
                                    key={`integrations-${index}`}
                                    className={cx(
                                        'ant-btn ant-btn-solid',
                                        `ant-btn-${item.type}`,
                                        `ant-btn-${item.color}`
                                    )}
                                >
                                    {item.icon && (
                                        <em className={`fal fa-${item.icon}`} />
                                    )}
                                    {item.name}
                                </Link>
                            ))}
                        </Space>
                    </div>
                </div>
            </div>
        </section>
    )
}