const { defaultLanguage } = require('../../prismic-configuration')


exports.linkResolver = (doc) => {
    // URL for a category type
    // Backup for all other types
    switch (doc.type) {
        case 'homepage': {
            return doc.lang === defaultLanguage
                ? '/'
                : `/${doc.lang}`
        }
        case 'page': {
            return doc.lang === defaultLanguage
                ? `/${doc.uid}`
                : `/${doc.uid}/${doc.lang}`
        }
        case 'sector': {
            return doc.lang === defaultLanguage
                ? `/sector/${doc.uid}`
                : `/sector/${doc.uid}/${doc.lang}`
        }
        case 'politicas': {
            return doc.lang === defaultLanguage
                ? `/politicas/${doc.uid}`
                : `/politicas/${doc.uid}/${doc.lang}`
        }
        case 'form': {
            return doc.lang === defaultLanguage
                ? `/${doc.uid}`
                : `/${doc.uid}/${doc.lang}`
        }

        default:
            return '/'
    }
}