import * as React from 'react'
import { navigate } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'
import { Select } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'

export const LanguageSwitcher = ({ activeDoc }) => {
    const currentLang = activeDoc.lang

    const alternateLangOptions = activeDoc.alternateLanguages.map(
        (altLang, index) => (
            <Select.Option value={linkResolver(altLang)} key={`alt-lang-${index}`}>
                {altLang.lang === 'es-co' ? 'Español' : 'English'}
            </Select.Option>
        ),
    )

    const handleLangChange = (event) => {
        navigate(event)
    }

    return (
        <>
            <Select
                defaultValue={currentLang === 'es-co' ? 'ESP' : 'ENG'}
                onChange={handleLangChange}
                className='ant-select-lang'
                placement='bottomRight'
                data-id='lang'
                suffixIcon={<FontAwesomeIcon icon={faChevronDown} />}
            >
                {alternateLangOptions}
            </Select>
        </>
    )
}