// @vendors
import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

// @compoenets
import { components } from '../../components'
import SeoAttributes from '../../components/seo'

// @layout
import { Layout } from '../../layouts/generic'

const PageTemplate = ({ data }) => {
    if (!data) return null

    const page = data.prismicPage || {}
    const header = data.prismicHeader || {}
    const footer = data.prismicFooter || {}
    const generics = data.prismicGenerics || {}
    const testimonials = data.prismicTestimonials || {}

    const { lang, type, url } = page || {}
    const alternateLanguages = page.alternate_languages || []

    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const contextPage = {
        generics,
        testimonials
    }

    return (
        <Layout
            header={header}
            footer={footer}
            activeDoc={activeDoc}
        >
            <SeoAttributes data={page.data.properties[0]} />
            <SliceZone
                slices={page.data.body}
                components={components}
                context={contextPage}
            />
        </Layout>
    )
}

export const query = graphql`
  query pageQuery($lang: String) {
    prismicPage(lang: { eq: $lang }) {
        _previewable
        alternate_languages {
            uid
            type
            lang
        }
        lang
        url
        type
        data {
            properties {
                description
                keywords
                title
            }
            body {
                ... on PrismicSliceType {
                    id
                    slice_type
                    slice_label
                }
                ... on PrismicPageDataBodyBanner {
                    items {
                      name
                      type
                      color
                      link {
                        url
                        uid
                        type
                        id
                        lang
                      }
                    }
                    primary {
                      title
                      background
                      color
                      type
                      description {
                        richText
                      }
                      highlight
                      image {
                        alt
                        url
                      }
                    }
                }
                ... on PrismicPageDataBodyHacemosLaDiferencia {
                    items {
                        description
                        title
                        icon
                    }
                    primary {
                        background
                        color
                        title_md: title {
                            richText
                        }
                        description {
                            richText
                        }
                    }
                }
                ... on PrismicPageDataBodyStacked {
                    items {
                        description
                        title
                        image {
                            alt
                            url
                        }
                    }
                    primary {
                        align
                        type
                        support
                        title
                        message: description
                        color
                        background
                    }
                }
                ... on PrismicPageDataBodyTestimonio {
                    primary {
                        specify
                        background
                        color
                        type
                    }
                }
                ... on PrismicPageDataBodyContactanos {
                    items {
                        button_link {
                            lang
                            type
                            uid
                            url
                            id
                        }
                        button_name
                        color
                        type
                    }
                    primary {
                        align
                        background
                        color
                        type
                    }
                }
            }
        }
    }
    prismicGenerics(lang: { eq: $lang }) {
        _previewable
        lang
        type
        data {
            contactus {
                description
                title
                image {
                    url
                    alt
                }
                alternative
            }
            support {
                button_name
                message
                image {
                    alt
                    url
                }
                button_link {
                    url
                    uid
                    type
                    id
                }
            }
        }
    }
    prismicHeader(lang: { eq: $lang }) {
        ...HeaderFragment
    }
    prismicTestimonials(lang: { eq: $lang }) {
        ...TestimonialsFragment
    }
    prismicFooter(lang: { eq: $lang }) {
        ...FooterFragment
    }
}`

export default withPrismicPreview(PageTemplate)