// @vendors
import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

// @compoenets
import { components } from '../../components'
import SeoAttributes from '../../components/seo'

// @layout
import { Layout } from '../../layouts/generic'

const HomepageTemplate = ({ data }) => {
  if (!data) return null

  const homepage = data.prismicHomepage || {}
  const header = data.prismicHeader || {}
  const footer = data.prismicFooter || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []

  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const context = {
    type: 'home'
  }

  return (
    <Layout
      header={header}
      footer={footer}
      activeDoc={activeDoc}
    >
      <SeoAttributes data={homepage.data.properties[0]} />
      <SliceZone
        slices={homepage.data.body}
        components={components}
        context={context}
      />
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        properties {
          description
          keywords
          title
        }
        body{
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ... on PrismicHomepageDataBodyBanner {
            items {
              type
              color
              icon
              external_content
              name
            }
            primary {
              title
              background
              color
              type
              description {
                richText
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyCaracteristicas {
            items {
              description
              image {
                alt
                copyright
                url
              }
              title
            }
            primary {
              description {
                richText
              }
              section_title
            }
          }
          ... on PrismicHomepageDataBodyAsiFunciona {
            items {
              description
              icon
              title
            }
            primary {
              title
            }
          }
          ... on PrismicHomepageDataBodyEstadisticas {
            items {
              message
              value
            }
            primary {
              background
              color
              title
            }
          }
          ... on PrismicHomepageDataBodyIntegraciones {
            primary {
              message: description
              subtitle
              title
              background
              color
              type
            }
            items {
              color
              name
              type
              icon
              link {
                id
                lang
                link_type
                type
                uid
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyStacked {
            items {
                description
                title
                image {
                    alt
                    url
                }
            }
            primary {
                align
                type
                support
                title
                message: description
                color
                background
            }
          }
          ... on PrismicHomepageDataBodyEmpresas {
            items {
              logo {
                alt
                url
              }
            }
            primary {
              title
              background
              color
              type
            }
          }
        }
      }
    }
    prismicHeader(lang: { eq: $lang }) {
      ...HeaderFragment
    }
    prismicFooter(lang: { eq: $lang }) {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(HomepageTemplate)