// @vendors
import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

// @compoenets
import SeoAttributes from '../../components/seo'
import Formu from '../../components/form'

// @constants
import { DEFAULT } from '../../constants/strings'

// @layout
import { LayoutSimple } from '../../layouts/simple'

const FormTemplate = ({ data }) => {
    if (!data) return null

    const today = new Date().getFullYear()
    const page = data.prismicForm || {}
    const header = data.prismicHeader || {}

    const { lang, type, url } = page || {}
    const alternateLanguages = page.alternate_languages || []

    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const primary = {
        title: page.data.title,
        description: page.data.description,
        email: page.data.email,
        cta_name: page.data.cta,
        reset_name: page.data.reset_name,
        left_image: page.data.left_image,
        right_image: page.data.right_image,
        model: page.data.model
    }

    const sectors = header.data.sectors.map(({ title }) => title)

    return (
        <LayoutSimple
            header={header}
            activeDoc={activeDoc}
        >
            <SeoAttributes data={page.data.properties[0]} />
            <Formu
                data={primary}
                fields={page.data.body}
                sectors={sectors}
                lang={lang}
            />
            <div className='form-container-footer'>
                <span>
                    © Copyright {today}
                </span>
                <Link to='/'>
                    {DEFAULT.generics.legal[lang].privacy}
                </Link>
            </div>
        </LayoutSimple>
    )
}

export const query = graphql`
  query FormQuery($id: String, $lang: String) {
    prismicForm(id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        alternate_languages {
            uid
            type
            lang
            id
        }
        lang
        url
        uid
        type
        data {
            title
            email
            cta
            reset_name
            model
            left_image {
                url
                alt
            }
            right_image {
                url
                alt
            }
            description {
                richText
            }
            properties {
                description
                keywords
                title
            }
            body {
                ... on PrismicSliceType {
                    id
                    slice_type
                    slice_label
                }
                ... on PrismicFormDataBodyText {
                    primary {
                        label
                        regex
                        required
                        validation
                    }
                }
                ... on PrismicFormDataBodySelect {
                    items {
                        option
                    }
                    primary {
                        label
                        required
                        validation
                    }
                }
                ... on PrismicFormDataBodyEmail {
                    primary {
                        label
                        required
                        validation
                    }
                }
                ... on PrismicFormDataBodySector {
                    primary {
                        label
                        required
                        validation
                    }
                }
                ... on PrismicFormDataBodyTextarea {
                    primary {
                        label
                        maxlength
                        required
                        validation
                    }
                }
            }
        }
    }
    prismicHeader(lang: { eq: $lang }) {
        ...HeaderFragment
    }
}`

export default withPrismicPreview(FormTemplate)