// @vendors
import * as React from 'react'
import cx from 'classnames'

// @components
import { Header } from '../../components/header'

// @styles
import './styles.scss'

export const LayoutSimple = ({ children, header, activeDoc }) => {
    const classes = cx(
        'form-container'
    )
    return (
        <div
            className={cx(classes)}
        >
            <Header
                data={header}
                activeDoc={activeDoc}
                type='simple'
            />
            {children}
        </div>
    )
}