// @vendors
import { PrismicRichText } from '@prismicio/react'
import React from 'react'
import cx from 'classnames'

// @styles
import './styles.scss'

export default function MakeDifference({ slice }) {
    const data = slice.primary
    const items = slice.items

    return (
        <section className={cx(
            'section-default make-difference wave',
            `c-${data?.background}`,
            {
                'text-dark': data?.color === 'Oscuro',
                'text-light': data?.color === 'Claro'
            }
        )}>
            <div className='container'>
                {data.title_md && (
                    <PrismicRichText
                        field={data.title_md.richText}
                        components={{
                            paragraph: ({ children }) => <h3 className="title">{children}</h3>,
                            em: ({ children }) => <span className="title-mask-wave">{children}</span>
                        }}
                    />
                )}
                {data.description && (
                    <div className='make-difference-description'>
                        <PrismicRichText
                            field={data.description.richText}
                            components={{
                                paragraph: ({ children }) => <p className="parag">{children}</p>
                            }}
                        />
                    </div>
                )}

                <div className='make-difference-content'>
                    {items && items.map((item, index) => (
                        <div
                            className='make-difference-item'
                            key={`make-difference-item-${index}`}
                        >
                            <div className='make-difference-item-icon'>
                                <em className={`fat fa-${item.icon}`} />
                            </div>
                            {item.title && (
                                <h3 className='subtitle'>
                                    <strong>{item.title}</strong>
                                </h3>
                            )}
                            {item.description && (
                                <p className='parag'>
                                    {item.description}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}