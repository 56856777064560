// @vendors
import React, { useEffect, useState } from "react";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Button, Divider, Form, Input } from 'antd';
import { Link } from "gatsby";
import emailjs from '@emailjs/browser';

// @components
import { fieldsComponents } from "./fields";
import MessageForm from "./message";

// @constants
import { DEFAULT } from "../../constants/strings";

// @styles
import './styles.scss'
import FieldMessages from "./fields/messages";

export default function Formu({ data, fields, sectors, lang }) {
    const [formElement, setFormElement] = useState('')
    const [form] = Form.useForm();
    const [formSend, setFormSend] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setFormElement(document.getElementById(`form-${data.model}-${lang}`))
    }, [data.model, lang])

    const onFinish = (values) => {
        setLoading(true)
        emailjs.sendForm('default_service', 'template_hndjrjt', formElement, 'sv0o5h_oNIuWFZN2m')
            .then(() => {
                onReset()
                setFormSend(true)
            }, () => {
                setLoading(false)
            });

    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const onReset = () => {
        form.resetFields();
    }

    return (
        <section className='form'>
            {data.left_image && (
                <img
                    src={data.left_image.url}
                    alt={data.left_image.alt}
                    className='form-left-image'
                />
            )}
            {data.right_image && (
                <img
                    src={data.right_image.url}
                    alt={data.right_image.alt}
                    className='form-right-image'
                />
            )}
            <div className="container">
                {data.title && (
                    <h1 className="subtitle">
                        <strong>{data.title}</strong>
                    </h1>
                )}
                {data.description && (
                    <div className="form-description">
                        <PrismicRichText
                            field={data.description.richText}
                            components={{
                                paragraph: ({ children }) => <p className="parag">{children}</p>
                            }}
                        />
                    </div>
                )}
                {(data.title || data.description) && (
                    <Divider />
                )}
                {formSend && (
                    <>
                        <MessageForm lang={lang} type='success' model={data.model} />
                        <Link
                            to="/"
                            className='ant-btn ant-btn-solid ant-btn-basic'
                            style={{ margin: '20px auto 0' }}
                        >
                            {DEFAULT.generics.buttons[lang].goHome}
                        </Link>
                    </>
                )}
                {!formSend && (
                    <Form
                        id={`form-${data.model}-${lang}`}
                        layout='vertical'
                        className="ant-form ant-form-vertical ant-form-basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                        autoComplete="off"
                    >
                        <FieldMessages lang={lang} model={data.model} />
                        <Form.Item
                            label=''
                            hidden
                        >
                            <Input name="sentTo" defaultValue={data.email} />
                        </Form.Item>

                        <SliceZone
                            slices={fields}
                            components={fieldsComponents}
                            context={{ sectors, lang }}
                        />
                        <Form.Item className="ant-form-actions">
                            <Button
                                htmlType="button"
                                onClick={onReset}
                                type='text'
                                disabled={loading}
                            >
                                {data.reset_name}
                            </Button>
                            <Button
                                type="primary"
                                className="ant-btn-solid c-1"
                                htmlType="submit"
                                loading={loading}
                            >
                                {data.cta_name}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </section>
    )
}