import React from 'react'

export default function Logo() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={118}
            height={41}
        >
            <path
                d="M74.956 10.883h.55-1.1.55Zm-27.942-.36c-2.384-.06-4.708.84-6.48 2.46v-1.8a6.424 6.424 0 0 0-1.835-.24c-.612 0-1.284.06-1.895.24v26.581h3.79l-.06-11.46a9.26 9.26 0 0 0 6.419 2.4c4.953.18 9.11-3.66 9.294-8.52v-.54c.06-5.4-3.913-9.121-9.233-9.121Zm0 14.94c-3.18.12-5.87-2.34-5.992-5.52v-.36c.123-3.24 2.874-5.82 6.176-5.7 3.179.12 5.686 2.58 5.808 5.7.122 3.18-2.446 5.82-5.625 5.88h-.367Zm28.492-14.58h-1.1c-.428.06-.917.12-1.345.24v1.26c-.367-.3-.795-.54-1.223-.78-1.406-.78-3.057-1.14-4.647-1.14-5.38 0-9.232 3.84-9.232 9.06 0 5.22 3.851 9.061 9.232 9.061 2.14.06 4.158-.66 5.87-1.92v1.5h3.79V11.123c-.427-.12-.917-.18-1.345-.24Zm-8.315 14.58c-3.18.12-5.87-2.34-5.992-5.52v-.36c.122-3.24 2.874-5.82 6.175-5.7 3.18.12 5.687 2.58 5.809 5.7.122 3.18-2.446 5.82-5.625 5.88h-.367Zm16.753-5.94v8.761h-3.79V11.123c.61-.18 1.222-.24 1.895-.24.611 0 1.222.06 1.773.24v1.8a6.264 6.264 0 0 0 .122 6.6Z"
                fill="#182134"
            />
            <path
                d="M90.73 11.063c-.244-.06-.55-.12-.794-.18l-1.04-.06c-.55 0-1.1.06-1.65.24-.123 0-.245.06-.367.12-1.223.42-2.324 1.26-2.996 2.34-.612.96-.917 2.04-.856 3.18 0 .54.06 1.08.183 1.56.184.66.428 1.26.795 1.8 0-.54.061-1.08.245-1.62a5.68 5.68 0 0 1 5.625-4.2h.122c.245 0 .49 0 .734.06a7.247 7.247 0 0 0 0-3.24Zm4.953-8.04c-.611 0-1.284.06-1.895.24l1.895-.24Zm1.896.24c-.612-.18-1.223-.3-1.896-.24l1.896.24Z"
                fill="#182134"
            />
            <path
                d="M97.579 3.323v24.961h-3.73V3.263c.611-.18 1.223-.24 1.895-.24.612 0 1.223.06 1.835.3Zm17.303 7.56h-.489l-3.913 10.44-.918 2.4-1.222-2.82-4.342-10.02h-.489c-1.161.06-2.201.42-3.179 1.08l3.363 7.32 1.467 3.24.061.12.123.24.428.9.55 1.14.306.72c.366.84.611 1.74.55 2.641 0 1.44-.489 2.82-1.406 3.96-1.101 1.26-2.691 1.98-4.341 2.04h-.673v3.42h.367c4.28 0 7.643-2.34 8.988-5.88.489-1.02.978-2.1 1.345-3.12l2.384-6.06L118 11.963a5.733 5.733 0 0 0-3.118-1.08Z"
                fill="#182134"
            />
            <path
                d="M3.053 32.412a9.02 9.02 0 0 1 6.298-2.58v2.16c-.061 2.58-1.223 4.98-3.24 6.6-1.59 1.26-3.608 1.98-5.626 1.98v-1.98a8.16 8.16 0 0 1 2.568-6.18Z"
                fill="url(#a)"
            />
            <path
                d="M15.281.73v8.76h-.06c-3.302 0-5.931 2.581-5.931 5.821v5.82c-4.892 0-8.927 3.9-8.927 8.761v-14.58C.424 7.27 7.027.73 15.28.73Z"
                fill="url(#b)"
            />
            <path
                d="M9.35 21.131v8.761c-4.89 0-8.926 3.9-8.926 8.7v-8.7c0-4.86 3.974-8.76 8.927-8.76Z"
                fill="url(#c)"
            />
            <path
                d="M30.078 15.311c0 8.04-6.603 14.581-14.796 14.581H9.35v-8.76h5.93c3.302-.12 5.87-2.76 5.748-6-.061-3.061-2.568-5.521-5.748-5.641h-.06V.73h.06c8.194.06 14.797 6.54 14.797 14.58Z"
                fill="url(#d)"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={3.973}
                    y1={44.409}
                    x2={5.316}
                    y2={31.035}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1DC6FF" />
                    <stop offset={0.89} stopColor="#309DFF" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={-0.898}
                    y1={78.173}
                    x2={9.95}
                    y2={-7.078}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1DC6FF" />
                    <stop offset={0.89} stopColor="#309DFF" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={5.506}
                    y1={38.249}
                    x2={4.362}
                    y2={21.426}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1DC6FF" />
                    <stop offset={0.89} stopColor="#23B9FF" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={20.344}
                    y1={30.905}
                    x2={18.259}
                    y2={0.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1DC6FF" />
                    <stop offset={0.89} stopColor="#309DFF" />
                </linearGradient>
            </defs>
        </svg>
    )
}