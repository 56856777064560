// @vendors
import React from 'react'
import cx from 'classnames'

// @styles
import './styles.scss'

export default function Stats({ slice }) {
    const items = slice.items
    const data = slice.primary

    return (
        <section className={cx(
            'section-default stats wave',
            `c-${data?.background}`,
            `${data?.wave && 'wave'}`,
            {
                'text-dark': data?.color === 'Oscuro',
                'text-light': data?.color === 'Claro'
            }
        )}>
            <div className='container'>
                {data?.title && (
                    <h3 className='title'>
                        <strong>{data.title}</strong>
                    </h3>
                )}
                {data?.message && (
                    <p className='parag'>
                        {data.message}
                    </p>
                )}
                <div className='stats-content'>
                    {items.map((item, index) => (
                        <div
                            className='stats-item'
                            key={`how-it-works-${index}`}
                        >
                            <div className='stats-value'>
                                {item.value}
                            </div>
                            <div className='stats-message parag'>
                                {item.message}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}