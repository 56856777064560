export const isBrowser = typeof window !== `undefined`;

export const getPathname = () => {
  const pathname = isBrowser ? window.location.pathname : "";

  return pathname;
};

export const getScrollPosition = () => {
  const position = isBrowser ? window.scrollY : "";

  return position;
};