import FieldText from './text'
import FieldEmail from './email'
import FieldSector from './sector'
import FieldTextarea from './textarea'

export const fieldsComponents = {
    text: FieldText,
    email: FieldEmail,
    sector: FieldSector,
    textarea: FieldTextarea,
}