import React from "react"
import { Form, Input } from 'antd';
import { convertToSnakeCase } from "../../../../utils/converts";

export default function FieldText({ slice }) {
    const data = slice.primary

    return (
        <Form.Item
            label={data.label}
            name={convertToSnakeCase(data.label)}
            rules={[
                { required: data.required, message: data.validation }
            ]}
        >
            <Input name={convertToSnakeCase(data.label)} />
        </Form.Item>
    )
}