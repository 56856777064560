import { Space } from "antd";
import { Link } from "gatsby";
import React from "react";
import cx from 'classnames'
import { linkResolver } from "../../../utils/linkResolver";

export default function ContactMessage({ data, actions }) {

    return (
        <>
            {data.title && (
                <h3 className="title">
                    <strong>{data.title}</strong>
                </h3>
            )}
            {data.description && (
                <p className="parag">
                    {data.description}
                </p>
            )}
            {actions && (
                <Space
                    size='large'
                >
                    {actions.map((item, index) => (
                        <Link
                            to={linkResolver(item.button_link)}
                            key={`contact-button-${index}`}
                            className={cx(
                                'ant-btn',
                                `ant-btn-${item.type}`,
                                `ant-btn-${item.color}`
                            )}
                        >
                            {item.button_name}
                        </Link>
                    ))}
                </Space>
            )}
            {/* {data.button_link && (
                <Button
                    type="solid"
                    className="ant-btn-primary-dark"
                >
                    {data.button_name}
                </Button>
            )} */}
            <Space
                size='middle'
                style={{ maxWidth: 300, marginTop: 30 }}
            >
                <img src={data.image.url} alt={data.image.alt} style={{ height: 40 }} />
                <p className="parag" style={{ fontSize: 16, lineHeight: 1.2 }}>{data.alternative}</p>
            </Space>
        </>
    )
}