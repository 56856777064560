// @vendors
import React from 'react'
import cx from 'classnames'

// @components
import SupportMessage from '../generics/support-message'

// @styles
import './styles.scss'

export default function Stacked({ slice, context }) {
    const data = slice.primary
    const items = slice.items

    return (
        <section
            className={cx(
                'section-default',
                'stacked',
                `c-${data.background}`,
                data.align && `align-${data.align}`,
                `align-${data.orientation}`,
                {
                    'wave': data.type === 'Wave',
                    'text-dark': data.color === 'Oscuro',
                    'text-light': data.color === 'Claro'
                }
            )}
        >
            <div className='container'>
                {data.title && (
                    <h3 className='title'>
                        <strong>{data.title}</strong>
                    </h3>
                )}
                {data.message && (
                    <p className='parag'>
                        {data.message}
                    </p>
                )}
                <div className='stacked-content'>
                    {items && items.map((item, index) => (
                        <div
                            className={cx(
                                'stacked-item',
                                item.shape && `shape-${item.shape}`,
                                {
                                    'stacked-item-start': item.orientation === 'flex-start',
                                    'stacked-item-end': item.orientation === 'flex-end'
                                }
                            )}
                            key={`stacked-item-${index}`}
                        >
                            <div className='stacked-item-image'>
                                <img src={item.image.url} alt={item.image.alt} />
                            </div>
                            <div className='stacked-item-content'>
                                {item.title && (
                                    <h3 className='subtitle'>
                                        <strong>{item.title}</strong>
                                    </h3>
                                )}
                                {item.description && (
                                    <p className='parag'>
                                        {item.description}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                {data.support && (
                    <SupportMessage data={context.generics.data.support[0]} />
                )}
            </div>
        </section>
    )
}