// @vendors
import { graphql } from 'gatsby'
import React from 'react'
import cx from 'classnames'
import { PrismicRichText } from '@prismicio/react'

// @styles
import './styles.scss'

export default function Testimonial({ slice, context }) {
    const data = slice.primary
    const testimonials = context.testimonials.data.body
    // const randomNumber = data.specify ? data.specify - 1 : Math.floor(Math.random() * testimonials.length)
    const testimonial = testimonials[0].primary

    return (
        <section
            className={cx(
                'section-default',
                'testimonial',
                `c-${data.background}`,
                {
                    'wave': data.type === 'Wave',
                    'text-dark': data.color === 'Oscuro',
                    'text-light': data.color === 'Claro'
                }
            )}
        >
            <div className='container'>
                <div className='testimonial-content'>
                    {testimonial.message && (
                        <PrismicRichText
                            field={testimonial.message.richText}
                            components={{
                                paragraph: ({ children }) => <h3 className="title">"{children}"</h3>
                            }}
                        />
                    )}
                    {testimonial.author && (
                        <div className='parag'>
                            {testimonial.author}
                        </div>
                    )}
                </div>
                <div className='testimonial-image'>
                    {testimonial.image.url && (
                        <img
                            src={testimonial.image.url}
                            alt={testimonial.image.alt}
                        />
                    )}
                    {testimonial.client.url && (
                        <div className='testimonial-logo'>
                            <img
                                src={testimonial.client.url}
                                alt={testimonial.client.alt}
                            />
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export const query = graphql`
fragment TestimonialsFragment on PrismicTestimonials {
    _previewable
    lang
    type
    data {
        body {
            ... on PrismicTestimonialsDataBodyNuevoTestimonio {
                primary {
                    author
                    message {
                        richText
                    }
                    image {
                        alt
                        url
                    }
                    client {
                        alt
                        url
                    }
                }
            }
        }
    }
}
`