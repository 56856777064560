// @vendors
import React from "react"
import { Space } from 'antd';
import { Link, graphql } from "gatsby";

// @svgs
import Logo from "../svg/logo";

// @styles
import './styles.scss'
import { linkResolver } from "../../utils/linkResolver";

export const Footer = ({ data, activeDoc }) => {
    if (!data) return null
    
    const footer = data?.data

    console.log({ footer })

    return (
        <footer className='footer'>
            <div className="container">
                <div className="footer-left">
                    <Link to=''>
                        <Logo />
                    </Link>
                </div>
            </div>
            <div className="container">
                <Space>
                    {footer.tacs && footer.tacs.map((item, index) => item.link.uid && (
                        <Link
                            to={linkResolver(item.link)}
                            key={`tac-${index}`}
                        >
                            {item.label}
                        </Link>
                    ))}
                </Space>
                <span>
                    © {activeDoc.lang === 'es-co'
                        ? 'Desde'
                        : 'From'
                    } 2016  <span>- </span>
                    {activeDoc.lang === 'es-co'
                        ? 'Todos los derechos reservados'
                        : 'All rights reserved'
                    }
                </span>
            </div>
        </footer>
    )
}

export const query = graphql`
fragment FooterFragment on PrismicFooter {
    _previewable
    lang
    type
    data {
        tacs {
            label
            link {
              url
              uid
              link_type
              type
              target
              lang
            }
        }
    }
}`