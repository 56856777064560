// @vendors
import { PrismicRichText } from '@prismicio/react'
import { Space } from 'antd'
import React from 'react'
import cx from 'classnames'

// @styles
import './styles.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'gatsby'
import { linkResolver } from '../../utils/linkResolver'

export default function Banner({ slice, context }) {
    const data = slice.primary
    const actions = slice.items

    return (
        <section
            className={cx(
                'section-default banner',
                `banner-${context.type}`,
                `c-${data.background}`,
                {
                    'wave': data.type === 'Wave',
                    'text-dark': data.color === 'Oscuro',
                    'text-light': data.color === 'Claro',
                    'align-vertical': data.orientation === 'Vertical',
                    'no-image': !data.image.url
                }
            )}
        >
            <div className='container'>
                <div className='banner-content'>
                    {data.title && (
                        <h1 className='banner-title title'>
                            <strong>{data.title}</strong>
                        </h1>
                    )}
                    <PrismicRichText
                        field={data.description.richText}
                        components={{
                            paragraph: ({ children }) => <p className="parag">{children}</p>,
                        }}
                    />
                    {actions && (
                        <Space size='large' className='banner-actions'>
                            {actions.map((item, index) => item.link && (
                                <Link
                                    className={cx(
                                        'ant-btn',
                                        `ant-btn-${item.type}`,
                                        `ant-btn-${item.color}`
                                    )}
                                    to={linkResolver(item.link)}
                                    key={`banner-action-${index}`}
                                >
                                    {item.icon && (
                                        <em className={`fas fa-${item.icon}`} />
                                    )}
                                    {item.name}
                                </Link>
                            ))}
                        </Space>
                    )}
                    {data.highlight && (
                        <div className='banner-highlight'>
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                            />
                            <span>{data.highlight}</span>
                        </div>
                    )}
                </div>
                {data.image.url && (
                    <div className='banner-media'>
                        <img
                            src={data.image.url}
                            alt={data.image.alt}
                            className=''
                        />
                    </div>
                )}
            </div>
        </section>
    )
}