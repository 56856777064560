// @vendors
import React, { useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'
import { Space, Popover, Dropdown, Menu } from 'antd'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'

// @components
import { LanguageSwitcher } from '../language-switcher'

// @svgs
import Logo from '../svg/logo'

// @utils
import { linkResolver } from '../../utils/linkResolver'
import { getPathname, getScrollPosition, isBrowser } from '../../utils/isBrowser'

// @styles
import './styles.scss'

export const Header = ({ data, activeDoc, type }) => {
    const pathname = getPathname();
    const [openNav, setOpenNav] = useState(false)
    const [openMegaNav, setOpenMegaNav] = useState(false)
    const [openSimpleNav, setOpenSimpleNav] = useState(false)
    const [position, setPosition] = useState(getScrollPosition())
    const [visible, setVisible] = useState(true)
    const header = data.data

    const handleScroll = () => {
        let moving = window.pageYOffset

        setVisible(position > moving)
        setOpenMegaNav(false)
        setOpenSimpleNav(false)
        setPosition(moving)

        if (position <= 100) {
            setVisible(true)
        }
    };

    useEffect(() => {
        if (isBrowser) {
            window.addEventListener('scroll', handleScroll);

            return (() => {
                window.removeEventListener('scroll', handleScroll);
            })
        }
    })

    return (
        <header className={cx(
            'header',
            type && `header-${type}`,
            {
                'header-visible': visible,
                'header-hidden': !visible,
                'header-static': openNav
            }
        )}>
            <div className='container'>
                <div className='header-left'>
                    <Link
                        to={linkResolver(header.home)}
                        className='header-logo'
                    >
                        <Logo />
                    </Link>
                    <a
                        href='https://pratechgroup.com'
                        target='_blank'
                        className='header-copy'
                        rel="noreferrer"
                    >
                        by
                        <span>Pratech</span>
                    </a>
                </div>
                <div
                    className={'header-right'}
                >
                    <Space
                        size='middle'
                        className={cx({ 'ant-space-active': openNav })}
                    >
                        {type !== 'simple' && (
                            <>
                                <nav className='ant-menu ant-menu-header ant-menu-horizontal'>
                                    <Popover
                                        content={
                                            <div className='ant-menu-mega'>
                                                {header.sectors.map((item, index) => (
                                                    <Link
                                                        to={linkResolver(item.link)}
                                                        className={cx(
                                                            'ant-menu-mega-item',
                                                            { 'ant-menu-mega-item-active': pathname === linkResolver(item.link) }
                                                        )}
                                                        key={`ant-menu-mega-item-${index}`}
                                                    >
                                                        <div className='ant-menu-mega-icon'>
                                                            <em className={`fad fa-${item.icon}`} />
                                                        </div>
                                                        <div className='ant-menu-mega-content'>
                                                            <div className='ant-menu-mega-title'>
                                                                {item?.title}
                                                            </div>
                                                            <div className='ant-menu-mega-description'>
                                                                {item?.description}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </div>
                                        }
                                        placement='bottom'
                                        getPopupContainer={() => document.getElementById('menu-sectors')}
                                        overlayClassName='ant-popover-mega-menu'
                                        showArrow={false}
                                        trigger='click'
                                        open={openMegaNav}
                                        onOpenChange={() => setOpenMegaNav(!openMegaNav)}
                                    >
                                        <li
                                            className={cx(
                                                'ant-menu-item',
                                                {
                                                    'ant-menu-item-active': pathname.includes('sector')
                                                }
                                            )}
                                            id='menu-sectors'
                                        >
                                            <div className='ant-menu-title-content'>
                                                {header.sectors_title}
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </div>
                                        </li>
                                    </Popover>
                                    {!header.we && (
                                        <Dropdown
                                            placement='bottom'
                                            overlay={
                                                <Menu
                                                    items={header.we
                                                        .map((item, index) => (
                                                            {
                                                                label: (
                                                                    <Link to={linkResolver(item.link)}>
                                                                        {item.title}
                                                                    </Link>
                                                                ),
                                                                key: `${index}`
                                                            }
                                                        ))
                                                    }
                                                />
                                            }
                                            trigger={['click']}
                                            overlayClassName='ant-dropdown-simple-menu'
                                            open={openSimpleNav}
                                            onOpenChange={() => setOpenSimpleNav(!openSimpleNav)}
                                            getPopupContainer={() => document.getElementById('menu-we')}
                                        >
                                            <li
                                                className={cx(
                                                    'ant-menu-item',
                                                    {
                                                        // 'ant-menu-item-active': pathname
                                                    }
                                                )}
                                                id='menu-we'
                                            >
                                                <div className='ant-menu-title-content'>
                                                    {header.we_title}
                                                    <FontAwesomeIcon icon={faChevronDown} />
                                                </div>
                                            </li>
                                        </Dropdown>
                                    )}
                                    {header.menu_items && header.menu_items.map((item, index) => (
                                        <li
                                            className={cx(
                                                'ant-menu-item',
                                                { 'ant-menu-item-active': pathname === linkResolver(item.link) }
                                            )}
                                            dir='rtl'
                                            key={index}
                                        >
                                            <Link
                                                to={linkResolver(item.link)}
                                                className='ant-menu-title-content'
                                            >
                                                {item.title}
                                            </Link>
                                        </li>
                                    ))}
                                </nav>
                                {header.cta && header.cta.map((item, index) => (
                                    <Link
                                        className={cx(
                                            'ant-btn',
                                            {
                                                'ant-btn-primary ant-btn-solid c-1': item.is_cta,
                                                'ant-btn-text': !item.is_cta
                                            }
                                        )}
                                        to={linkResolver(item.link)}
                                        data-id={item.data_id}
                                        key={`cta-${index}`}
                                    >
                                        {item.icon && <em className={`fas fa-${item.icon}`} />}
                                        <strong>{item.name}</strong>
                                    </Link>
                                ))}
                            </>
                        )}
                        <LanguageSwitcher
                            lang={data.lang}
                            activeDoc={activeDoc}
                        />
                    </Space>
                    {type !== 'simple' && (
                        <div
                            role='presentation'
                            className={
                                cx(
                                    'header-bars',
                                    { 'header-bars-active': openNav }
                                )
                            }
                            onClick={() => setOpenNav(!openNav)}
                        >
                            <span></span>
                        </div>
                    )}
                </div>
            </div>
        </header>
    )
}

export const query = graphql`
fragment HeaderFragment on PrismicHeader {
    _previewable
    lang
    type
    data {
        home {
            id
            url
            uid
            type
            lang
        }
        sectors_title
        we_title
        cta {
            data_id
            icon
            is_cta
            name
            link {
                id
                lang
                uid
                type
                url
            }
        }
        we {
            title
            link {
              id
              lang
              link_type
              uid
              type
              url
            }
        }
        sectors {
            title
            icon
            description
            link {
                id
                url
                uid
                type
                lang
            }
        }
        menu_items {
            title
            link {
                url
                type
                uid
                lang
            }
        }
    }
}
`