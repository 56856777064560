// @vendors
import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

// @compoenets
import { components } from '../../components'
import SeoAttributes from '../../components/seo'

// @layout
import { Layout } from '../../layouts/generic'

const PoliticasTemplate = ({ data }) => {
    if (!data) return null

    const page = data.prismicPoliticas || {}
    const header = data.prismicHeader || {}
    const footer = data.prismicFooter || {}
    const generics = data.prismicGenerics || {}

    const { lang, type, url } = page || {}
    const alternateLanguages = page.alternate_languages || []

    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    }

    const contextPage = {
        generics
    }

    console.log("page: ", page)

    return (
        <Layout
            header={header}
            footer={footer}
            activeDoc={activeDoc}
            className='with-spacing'
        >
            <SeoAttributes data={page.data.properties[0]} script={page.data.script} />
            <SliceZone
                slices={page.data.body}
                components={components}
                context={contextPage}
            />
        </Layout>

    )
}

export const query = graphql`
  query PoliticasQuery($id: String, $lang: String) {
    prismicPoliticas(id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        alternate_languages {
            uid
            type
            id
            lang
        }
        lang
        url
        uid
        type
        data {
            script
            properties {
                description
                keywords
                title
            }
            body {
                ... on PrismicSliceType {
                    id
                    slice_type
                    slice_label
                }
                ... on PrismicPoliticasDataBodyTexto {
                    primary {
                        contenido_politica {
                            richText
                        }
                    }
                }
            }
        }
    }
    prismicGenerics(lang: { eq: $lang }) {
        _previewable
        lang
        type
        data {
            contactus {
                description
                title
                image {
                    url
                    alt
                }
                alternative
            }
            support {
                button_name
                message
                image {
                    alt
                    url
                }
                button_link {
                    url
                    uid
                    type
                    id
                }
            }
        }
    }
    prismicHeader(lang: { eq: $lang }) {
        ...HeaderFragment
    }
    prismicFooter(lang: { eq: $lang }) {
        ...FooterFragment
    }
}`

export default withPrismicPreview(PoliticasTemplate)