exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-form-index-js": () => import("./../../../src/templates/form/index.js" /* webpackChunkName: "component---src-templates-form-index-js" */),
  "component---src-templates-homepage-index-js": () => import("./../../../src/templates/homepage/index.js" /* webpackChunkName: "component---src-templates-homepage-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-politicas-index-js": () => import("./../../../src/templates/politicas/index.js" /* webpackChunkName: "component---src-templates-politicas-index-js" */),
  "component---src-templates-sector-index-js": () => import("./../../../src/templates/sector/index.js" /* webpackChunkName: "component---src-templates-sector-index-js" */)
}

