// @vendors
import React from 'react'
import { Link } from 'gatsby'

// @styles
import './styles.scss'

export default function SupportMessage({ data }) {
    return (
        <div className="support-message">
            <img
                src={data.image.url}
                alt={data.image.alt}
                className='support-message-image'
            />
            {data.message && (
                <p className='parag'>
                    {data.message}
                </p>
            )}
            {data.button_link && (
                <Link className='ant-btn ant-btn-solid ant-btn-primary' to={data.button_link.url}>
                    {data.button_name}
                </Link>
            )}
        </div>
    )
}